import React, { useState} from 'react';
import {
  Card,
  Grid,
  makeStyles,
  CardContent,
  TextField,
  Button,
  Modal,
  Box
} from '@material-ui/core';
import { Form, Formik } from "formik";
import { labels } from 'src/labels';
import label from 'src/utils/label';
import CustomizedSnackbar from 'src/components/CustomizedSnackbar';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
        '&:last-child': {
        padding: 0
        }
    },
    details: {
        padding: theme.spacing(1, 3)
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: theme.spacing(1, 1)
    },
    button: {
        marginTop: theme.spacing(1)
    },
    comments: {
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
    },
    '.MuiSelect-select': {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
}
}));

const AgentModal = ({ pv, token, open, handleClose, agentId, ...props }) => {
    const classes = useStyles();
    const formData = {agentId: agentId}
    
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const snackBarAutoHideDuration = 2000;

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };

    return (
        <>
        <Modal
        style={{ zIndex: 1 }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Card className={classes.modal} >
        <CardContent className={classes.content}>
            <div className={classes.details}>
            <Formik
                enableReinitialize={true}
                initialValues = {formData}
                onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                    setSnackBarSeverity('info');
                    setSnackBarMessage('Loading. Please wait...')
                    setSnackBarOpen(true);
                    let data = {}
                    data.token = token;
                    data.agentId = values.agentId;
                    if(!pv) {
                        axios.post(API_URL + "/api/survey/agent", data)
                        .then(response => {
                            if (response.data) {
                                handleClose(values.agentId);
                                setSubmitting(false);
                                setSnackBarSeverity('success');
                                setSnackBarMessage('Successfully saved')
                                //setSnackBarOpen(false);
                                console.log("Successfully saved agent")
                            }
                        }).catch(error => {
                            setSubmitting(false);
                            setSnackBarSeverity('error');
                            setSnackBarMessage('Failed to save!')
                            setSnackBarOpen(false);
                            console.log(JSON.stringify(error));
                        });
                    } else {
                        handleClose(values.agentId);
                        setSubmitting(false);
                        setSnackBarSeverity('success');
                        setSnackBarMessage('Successfully saved')
                        setSnackBarOpen(false);
                        console.log("Successfully saved agent")
                    }
                }}
            >
                {({ values, touched, errors, status, handleChange, handleBlur, isValid, isSubmitting, dirty }) => (
                <Form noValidate autoComplete="off">
                    <Grid
                    alignItems="center"
                    container
                    spacing={4}
                    >
                    <Grid item md={12} xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            fullWidth rows={1}
                            variant="outlined"
                            color='secondary'
                            label={label(labels.get("agent"))}
                            name="agentId"
                            value={values.agentId}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button
                            className={classes.button}
                            type="submit"
                            color='secondary'
                            variant="contained"
                            disabled={(( !values.agentId ) || isSubmitting)}
                        >
                            {label(labels.get("save"))}
                        </Button>
                    </Grid>
                    {/* <Grid item md={12} xs={12}>
                        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                            <CustomizedSnackbar
                                open={snackBarOpen}
                                handleClose={handleSnackBarClose}
                                severity={snackBarSeverity}
                                msg={snackBarMessage}
                                autoHideDuration={snackBarAutoHideDuration}
                            />
                        </Box>
                    </Grid> */}
                    </Grid>
                </Form>
                )
                }
                </Formik >
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            
        </Box>
            </div>
        </CardContent>
        </Card>
        </Modal>
        <CustomizedSnackbar
        open={snackBarOpen}
        handleClose={handleSnackBarClose}
        severity={snackBarSeverity}
        msg={snackBarMessage}
        autoHideDuration={snackBarAutoHideDuration}
    />
    </>
    );
};

export default AgentModal;